<template>
  <div>
    <b-row>
      <b-col
        xs="12"
        sm="6"
        md="8"
        lg="8"
        xl="9"
        class="d-flex justify-content-between pb-1 align-items-center"
      >
        <div class="d-flex w-100 justify-content-between">
          <h1>
            {{ $t('expenses') }}
          </h1>
          <b-dropdown
            id="dropdown-1"
            variant="primary"
            :text="$t('new', { type: $t('expense') })"
            class="m-md-0"
          >
            <b-dropdown-item
              :to="{
                name: 'newExpense',
                params: { projectId: $route.params.projectId },
                query: { createType: 'direct_cost' },
              }"
            >{{ $t('directCost') }}</b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: 'newExpense',
                params: { projectId: $route.params.projectId },
                query: { createType: 'additives_and_changes' },
              }"
            >{{ $t('additivesAndChanges') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
      <b-col
        xs="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
      >
        <income-total-card
          :label="'totalExcomes'"
          :total-income="total"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="12"
        class="mb-2"
      >
        <div class="d-flex align-items-center">
          <span class="mr-2">{{ $t('show') }} </span>
          <b-form-select
            :value="expensesPagination.per_page"
            style="width:100px"
            :options="perPageOptions"
            @change="changePerPageValue($event)"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="6"
        sm="6"
        lg="3"
      >
        <b-form-group :label="$t('fromDate')">
          <b-form-datepicker
            v-model="query.dates.begin"
            reset-button
            @input="filterExpenses"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        sm="6"
        lg="3"
      >
        <b-form-group :label="$t('toDate')">
          <b-form-datepicker
            v-model="query.dates.end"
            reset-button
            @input="filterExpenses"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        sm="6"
        lg="3"
      >
        <b-form-group :label="$t('status')">
          <b-form-select
            v-model="query.status"
            :options="expenseStatusOptions"
            @change="filterExpenses"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        sm="6"
        lg="3"
      >
        <b-form-group :label="$t('supplier')">
          <b-form-input
            v-model="query.supplier"
            autocomplete="off"
            type="text"
            @change="filterExpenses"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <expenses-table
            :is-material="false"
            :expenses="expenses"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-pagination
          :value="expensesPagination.page"
          :total-rows="expensesPagination.total_objects"
          :per-page="expensesPagination.per_page"
          aria-controls="my-table"
          @change="nextPage($event)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ExpensesTable from '@/components/expenses/ExpensesTable.vue'
import IncomeTotalCard from '@/components/income/IncomeTotalCard.vue'

export default {
  components: {
    ExpensesTable,
    IncomeTotalCard,
  },
  data() {
    return {
      loading: false,
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
      ],
      expenseStatusOptions: [
        { text: this.$t('all'), value: null },
        { text: this.$t('paid'), value: 'paid' },
        { text: this.$t('credit'), value: 'credit' },
        { text: this.$t('expired'), value: 'expired' },
        { text: this.$t('received'), value: 'received' },
      ],
      query: {
        dates: {},
        status: null,
        supplier: null,
      },
    }
  },
  computed: {
    ...mapGetters('projects', ['project']),
    ...mapGetters('expenses', [
      'expenses',
      'expensesPagination',
      'expensesTotal',
    ]),
    total() {
      return `$${this.$options.filters.money(this.expensesTotal)}`
    },
    isMaterial() {
      return this.expenses[0]?.expense_type_catalog.name === 'Material'
    },
  },

  mounted() {
    this.loading = true
    this.fetchExpenses({
      projectId: this.$route.params.projectId,
      pagination: { page: 1, per_page: 10 },
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapMutations('expenses', {
      changePagination: 'CHANGE_PAGINATION',
    }),
    ...mapActions('expenses', ['fetchExpenses']),
    changePerPageValue(value) {
      this.changePagination({ field: 'per_page', value })
      this.loading = true
      this.fetchExpenses({
        projectId: this.$route.params.projectId,
        pagination: this.expensesPagination,
      }).finally(() => {
        this.loading = false
      })
    },
    nextPage(value) {
      this.changePagination({ field: 'page', value })
      this.loading = true
      this.fetchExpenses({
        projectId: this.$route.params.projectId,
        pagination: this.expensesPagination,
      }).finally(() => {
        this.loading = false
      })
    },
    filterExpenses() {
      let dates = {}
      if (this.query.dates.begin?.length) {
        dates.start_date = this.query.dates.begin
      }
      if (this.query.dates.end?.length) {
        dates.end_date = this.query.dates.end
      }
      if (!Object.keys(dates).length) {
        dates = null
      }
      this.loading = true
      this.fetchExpenses({
        projectId: this.$route.params.projectId,
        supplier: this.query.supplier,
        status: this.query.status,
        dates,
        pagination: this.expensesPagination,
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style></style>
