<template>
  <div>
    <statistic-card-horizontal
      icon="DollarSignIcon"
      color="success"
      :statistic="totalIncome"
      :statistic-title="$t(label)"
    />
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    StatisticCardHorizontal,
  },
  props: {
    totalIncome: {
      type: String,
      default() {
        return ''
      },
    },
    label: {
      type: String,
      default() {
        return 'totalIncome'
      },
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
